import React, { Component } from 'react'
import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet'
import './location.css'

export class Location extends Component {
  position = [49.854553, 19.9400653]

  render() {
    if (typeof window !== 'undefined') {
      return (
        <MapContainer center={this.position} zoom={12} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={this.position}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer>
      )
    }
    return null
  }
}